<template>
  <v-container fluid>
    <v-row class="mx-2 mt-5">
      <v-col cols="12">
        <h1 class="text-h2 pb-4 text-center">
          {{ $t('fallbackTemplate.title') }}
        </h1>
      </v-col>
      <v-col cols="12">
        <p class="subheading grey--text text--darken-3 text-center">
          {{ $t('fallbackTemplate.body1') }} <strong>{{ category }}</strong> {{ $t('fallbackTemplate.body2') }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'FallbackTemplate',
  props: {
    category: { type: String, default: '' }
  }
}
</script>
