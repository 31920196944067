<template>
  <v-component
    :is="listComponent"
    :key="$route.meta.viewContext"
  />
</template>

<script>
import { mapState } from 'vuex'
import TablePlaceholder from '@/components/service/TablePlaceholder'
import FallbackTemplate from '@/features/documents/components/details/FallbackTemplate'

export default {
  name: 'DocumentsList',
  components: {
    TablePlaceholder,
    FallbackTemplate,
    invoices: () => ({
      component: import('@/features/documents/components/list/Invoices'),
      loading: TablePlaceholder
    }),
    purchase_orders: () => ({
      component: import('@/features/documents/components/list/Orders'),
      loading: TablePlaceholder
    })
  },
  computed: {
    ...mapState({
      documentsGroups: state => state.documents.documentsGroups
    }),
    listComponent () {
      return this.documentsGroups.indexOf(this.$route.params.documentCategory) >= 0
        ? this.$route.params.documentCategory
        : 'FallbackTemplate'
    }
  }
}
</script>
