<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="py-0"
      >
        <v-skeleton-loader type="date-picker-options" />
      </v-col>
      <v-col
        cols="12"
        class="pt-0"
      >
        <v-card>
          <v-skeleton-loader type="table" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TablePlaceholder'
}
</script>
